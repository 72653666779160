@media only screen and (max-width: 900px) {
    div.partner-review-card {
        padding: 15px;
        flex-direction: column;
        align-items: center;
    }

    div.partner-review-card .review-text p {
        margin: 10px 0;
    }

    div.partner-review-card q,
    div.partner-review-card p,
    div.partner-review-card span {
        text-align: center;
    }

    div.partner-review-card .review-text q {
        font-size: 16px;
        line-height: 20px;
    }

    div.partner-review-card .image-wrapper {
        translate: 0;
    }

    div.partner-review-card img {
        width: 108px;
        margin: auto;
    }

    div#registration-form {
        flex-direction: column;
    }

    div#registration-form #bg-img {
        display: none;
    }

    div#registration-form #left {
        display: flex;
        justify-content: space-between;
    }

    div#org-logo-wrapper {
        margin: 0 20px 0 0;
    }

    #registration-form #left #headings-container>span:first-child {
        font-size: 10vw;
        line-height: 10vw;
    }

    #registration-form #left #headings-container>span:nth-child(2) {
        font-size: 5vw;
        line-height: 5vw;
    }

    #org-logo-wrapper {
        gap: 3vw;
    }

    #org-logo-wrapper img {
        width: 9vw;
    }

    #org-logo-wrapper span {
        font-size: 6vw;
        line-height: 3vw;
    }

    #merchant-form-wrapper>#headings>h2 {
        font-size: 6vmin;
        line-height: 6vmin;
    }

    #merchant-form-wrapper>#headings>span {
        font-size: 2.8vmin;
        line-height: 5vmin;
    }

    div#merchant-form-wrapper #logo-title-wrapper h2 {
        font-size: 10vmin !important;
        line-height: 10vmin !important;
    }

    form#merchant-from .input-wrapper input {
        padding: 2.25vmin 3vmin;
        font-size: 3.75vmin;
        line-height: 3.75vmin;
        border-radius: 2vmin;
    }

    form#merchant-from .input-wrapper label {
        font-size: 3.25vmin;
        line-height: 3.25vmin;
    }

    form#merchant-from>div {
        width: 80%;
    }

    form#merchant-from button {
        padding: 2vmin 2vmax;
        font-size: 3.75vmin;
    }

    div#pricing #left h2 {
        font-size: 7vmin;
        line-height: 7.75vmin;
    }

    div#pricing #top-content>div:first-child span {
        font-size: 7vmin;
    }

    #pricing #top-content>div:first-child p,
    #pricing #bottom-content p {
        font-size: 3vmin;
        line-height: 3.2vmin;
    }

    div#pricing #details {
        border-radius: 10px;
        margin: 0 10px;
    }

    div#pricing #details,
    div#pricing #top-content>div:last-child {
        padding: 3.25vmin;
    }

    div#pricing #top-content>div:last-child span,
    div#pricing #bottom-content span {
        font-size: 4.35vmin;
        line-height: 4.35vmin;
    }

    #pricing #bottom-content img {
        height: 16vmax;
    }

    #pricing #details>div {
        gap: 3vmin;
        padding: 4vmin;
    }

    #contact-us #head {
        gap: 5vw;
    }

    #contact-us #head h2 {
        font-size: 5vmin;
        line-height: 8vmin;
    }

    #contact-details {
        margin: auto 5vw;
        position: relative;
    }

    #contact-us #head p {
        font-size: 2.5vmin;
    }

    #contact-details span:first-child {
        font-size: 4vmin;
        line-height: 7vmin;
    }

    #contact-details span {
        font-size: 2.5vmin;
        line-height: 4vmin;
    }

    #contact-us #head {
        padding: 4vmax;
    }
}

@media only screen and (max-width: 768px) {

    #footer-links>div {
        flex-direction: column;
        gap: 0!important;
    }

    #footer-links span {
        text-align: center;
        margin: 0;
    }

    button.nav-actions.selected::after {
        padding: 4vmin !important;
    }

    button.gradient-btn {
        padding: 1.5vmin 6vmin !important;
        font-size: 3vmin !important;
    }

    #nav-menu {
        gap: 6.5vmin !important;
    }

    #nav-actions-wrapper {
        display: none !important;
    }

    #hamburger-menu-wrapper {
        display: block !important;
    }

    #homepage-intro {
        margin: 30px 0 0;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        gap: 30px;
    }

    #homepage-intro ul {
        font-size: 13px;
        padding: 20px 60px;
    }

    #homepage-intro img {
        width: 85vw;
        border-radius: 10px;
        margin-top: 18px;
    }

    .text-centralized {
        text-align: center !important;
    }

    .elem-centralized {
        display: block;
        margin: auto;
    }

    .heading {
        font-size: 28px !important;
        line-height: 54px;
    }

    #homepage-image-container img {
        margin: 100px 0;
    }

    #easy-integration {
        margin-top: -140px;
    }

    .long-card {
        width: 65vw;
    }

    #partner-images-wrapper {
        margin: 50px 2rem;
    }

    #partner-images-wrapper img {
        width: 112px;
    }

    .review-card-wrapper {
        margin: 0 10px;
    }

    .review-card {
        padding: 25px;
    }

    .review-card img {
        width: 72px;
    }

    .review-head div:last-child span {
        font-size: 20px;
    }

    .review-text-wrapper {
        margin: 15px 0;
    }

    .review-text-wrapper q {
        font-size: 17px;
    }

    .review-stars-wrapper svg {
        width: 25px;
    }

    #subscribe-wrapper {
        text-align: center;
        justify-content: center !important;
    }

    #footer {
        padding: 55px 60px 30px !important;
    }

    #footer-follow-links {
        flex-direction: column;
        gap: 10px !important;
    }

    #phone-icon-wrapper {
        padding: 1.25vmin;
        translate: 0 -70%;
        position: absolute;
    }

    #phone-icon-wrapper>div {
        padding: 3vmin;
    }

    #phone-icon-wrapper img {
        width: 5vmin;
    }
}

@media only screen and (max-width: 630px) {

    .heading {
        font-size: 22px !important;
        line-height: 30px;
    }

    #footer-follow-links {
        flex-direction: row;
        gap: 10px !important;
        margin: 10px 0;
    }

    div#footer-follow-links h2 {
        margin: 0 !important;
        font-size: 20px !important;
    }

    #footer-follow-links img {
        width: 24px !important;
    }

    #follow-links-wrapper {
        flex-direction: column;
        gap: 0 !important;
    }

    #logo-title-wrapper img {
        width: 10vw !important;
    }

    div#logo-title-wrapper {
        gap: 10px;
    }

    div#logo-title-wrapper h2 {
        font-size: 26px;
    }

    div#partner-images-wrapper {
        gap: 25px;
    }

    div#subscribe-wrapper {
        padding: 40px 50px;
    }

    #about-top p,
    div#about-section p,
    div#our-mission p {
        font-size: 14px;
        margin: 20px auto;
        font-weight: 300;
    }

    div#our-mission {
        margin: 0;
        padding: 30px 15px;
    }

    div#our-mission p {
        margin: 10px auto;
    }

    #brise-corousal .img-wrapper>div {
        padding: 10px;
        border-radius: 12px;
        border: 1px solid #ffffff78;
    }

    div.slick-slide {
        width: 100% !important;
    }

    #companies {
        padding: 20px 0 !important;
    }

    div#about-section h2 {
        margin: 25px 0;
    }

    div#about-section #images-wrapper {
        margin: 60px 0;
    }

    div#about-section #images-wrapper img {
        width: 68vw;
        translate: 0 !important;
    }

    #questions-wrapper {
        padding: 40px 25px 25px !important;
    }

    div#questions span {
        font-size: 18px;
    }

    div#questions div {
        padding: 14px 0;
    }

    div#questions-wrapper>button {
        padding: 10px 20px !important;
    }

    div#pricing {
        flex-direction: column;
    }

    div#pricing #left>div>h2 {
        display: inline;
        text-align: center;
    }

    div#pricing #left>div>h2:first-child {
        margin-right: 2vmin;
    }

    div#pricing #left>* {
        text-align: center;
    }

    div#pricing #left {
        flex-direction: row-reverse;
        padding: 5vmin;
    }

    div#layout-image-wrapper {
        display: none;
    }

    #need-more-help h2 {
        margin: 0;
    }

    #need-more-help #inputs {
        margin: 5vmin 0;
    }

    #need-more-help #inputs>div .user-input-elem {
        padding: 2vmax;
        font-size: 13px;
    }

    #need-more-help #inputs>div .user-input-elem::placeholder {
        font-size: 13px;
    }
}

@media only screen and (max-width: 485px) {

    small {
        font-size: 13px !important;
    }

    .heading {
        font-size: 24px !important;
        line-height: 38px;
    }

    #homepage-intro ul {
        font-size: 13px;
        padding: 15px 60px;
    }

    div.homepage-card>div {
        width: 70vw;
    }

    div#partner-images-wrapper img {
        width: 82px;
    }

    div#subscribe-email-wrapper * {
        height: 38px;
        line-height: 38px !important;
        padding: 0 15px !important;
        font-size: 14px !important;
    }

    div.review-card {
        padding: 20px;
    }

    div.review-card img {
        width: 68px;
    }

    div.review-head div:last-child span {
        font-size: 18px;
    }

    div.review-text-wrapper {
        margin: 10px 0;
    }

    div.review-text-wrapper q {
        font-size: 13px;
    }

    div.review-text-wrapper p {
        font-size: 10px;
    }

    div.review-stars-wrapper svg {
        width: 15px;
    }

    div.slick-slide {
        width: 85vw !important;
    }

    .partner-review-card .review-text q {
        font-size: 14px !important;
    }

    .partner-review-card .review-text p {
        font-size: 12px !important;
    }

    div.slick-slide>div {
        width: 88vw !important;
    }

    #phone-icon-wrapper {
        padding: 1vmin;
        translate: 0 -90%;
        position: absolute;
    }

    #phone-icon-wrapper>div {
        padding: 2.5vmin;
    }

    #phone-icon-wrapper img {
        width: 4vmin;
    }
}

@media only screen and (max-width: 400px) {
    div#footer-follow-links {
        flex-direction: column;
        gap: 10px;
    }
}