body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

* {
    font-family: Poppins !important;
}

:root {
    --white-shade-bg: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
            rgba(165, 239, 255, 0.2) 0%,
            rgba(110, 191, 244, 0.0447917) 77.08%,
            rgba(70, 144, 213, 0) 100%);
}

#app {
    background: linear-gradient(180deg, #05062A 0%, #050743 100%);
}

#page-content-wrapper {
    background-image: url("../assets/svg/curves-top.svg");
}

#navbar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 20px;
}

#logo-title-wrapper {
    display: flex;
    align-items: center;
}

#logo-title-wrapper img {
    width: 54px;
    aspect-ratio: 1;
}

#logo-title-wrapper h2 {
    color: #FFFFFF;
    font-size: 32px;
    font-weight: 400;
}

#nav-actions-wrapper {
    display: flex;
    gap: 30px;
}

.nav-actions {
    background: none;
    border: none;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    position: relative;
}

button.nav-actions.selected::after {
    content: "";
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    filter: blur(20px);
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}

button.gradient-btn {
    border: none;
    padding: 12px 20px;
    font-weight: 700;
    color: #FFFFFF;
    background: linear-gradient(120.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    border-radius: 100px;
    transition: all .25s ease;
}

button.gradient-btn:hover {
    box-shadow:
        0px 3.18213px 3.18213px rgba(5, 7, 64, 0.5),
        0px 27.6433px 35.1307px rgba(255, 255, 255, 0.07),
        0px 13.174px 8.78268px rgba(255, 255, 255, 0.06),
        0px 4.39134px 4.39134px rgba(255, 255, 255, 0.05),
        0px 4.39134px 3.51307px rgba(255, 255, 255, 0.04),
        0px 1.75654px 2.19567px rgba(255, 255, 255, 0.03),
        0px 0.789809px 0.878268px rgba(255, 255, 255, 0.02);
}

.gradient-text {
    background: linear-gradient(177.03deg, #15D0B8 6.72%, #3910DE 88.76%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.slick-slide {
    width: 60vw !important;
}

#subscribe-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    align-items: center;
    padding: 90px 50px;
    justify-content: space-between;
    color: #FFFFFF;
}

#subscribe-wrapper span,
#subscribe-wrapper small {
    text-align: left;
    display: block;
    font-family: Roboto;
}

#subscribe-wrapper span {
    font-size: 48px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0.02em;
}

#subscribe-wrapper small {
    line-height: 25px;
    letter-spacing: 0em;
}

#subscribe-email-wrapper {
    display: flex;
    align-items: center;
}

#subscribe-email-wrapper * {
    padding: 0 25px;
    height: 50px;
    display: flex;
    border-radius: 32px;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(165, 239, 255, 0.2) 0%, rgba(110, 191, 244, 0.0447917) 77.08%, rgba(70, 144, 213, 0) 100%);
    border: none;
    outline: none;
    color: white;
}

#subscribe-email-wrapper button {
    line-height: 50px;
    font-size: 21px;
    letter-spacing: 0em;
}

.gradient-border {
    position: relative;
}

.gradient-border:before {
    pointer-events: none;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px;
    background: radial-gradient(80.69% 208.78% at 108.28% 112.58%, #EABFFF 0%, rgba(135, 38, 183, 0) 100%), radial-gradient(80.38% 222.5% at -13.75% -12.36%, #98F9FF 0%, rgba(255, 255, 255, 0) 100%);
    mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

#footer {
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    padding: 55px 120px 30px;
    color: #FFFFFF;
}

#footer-links {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;
    flex-wrap: wrap;
}

#footer-links>div {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    /* justify-content: space-between; */
}

#footer-links span {
    display: block;
    font-size: 15px;
    line-height: 30px;
    white-space: nowrap;
    margin-right: 20px;
    cursor: pointer;
}

#follow-links-wrapper {
    margin: 15px 0;
}

#follow-links-wrapper,
#footer-follow-links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 35px;
}

#footer-follow-links>span {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}

#footer-follow-links>div {
    display: flex;
    gap: 20px;
    margin-left: 20px;
}

#footer-follow-links h2 {
    margin: 0;
    font-size: 42px;
}

div.slick-track {
    display: flex;
}

#nav-menu {
    color: #FFFFFF;
    background: linear-gradient(180deg, #05062A 0%, #050743 100%);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 1;
    gap: 10vmin;
    height: 100%;
    right: -120%;
    transition: all .5s ease;
    padding: calc(8vmin + 40px) 10vmin;
}

#nav-menu.active {
    right: 0;
}

#nav-menu::before {
    padding: 0 0 0 1px;
    background: #171959;
    border-radius: 0;
}

#nav-menu span:first-child {
    margin-left: auto;
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
}

#nav-menu button {
    text-align: left;
    width: fit-content;
    font-weight: 500;
}

#hamburger-menu-wrapper {
    display: none;
}