:root {
    --white-shade-bg: radial-gradient(90.16% 143.01% at 15.32% 21.04%,
            rgba(165, 239, 255, 0.2) 0%,
            rgba(110, 191, 244, 0.0447917) 77.08%,
            rgba(70, 144, 213, 0) 100%);
}

#homepage {
    color: #FFFF;
}

#homepage-intro {
    display: grid;
    grid-template-columns: 1.15fr 1fr;
    align-items: center;
    margin-top: 50px;
    margin-left: 100px;
}

#homepage-intro ul {
    padding: 0 20px;
    margin: 20px 0 30px;
}

#homepage-intro img {
    width: 100%;
    border-radius: 10px 0 0 10px;
}

#homepage-intro li {
    line-height: 30px;
    letter-spacing: 1px;
}

#homepage-intro>div:first-child h2 {
    font-size: 46px;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-align: left;
    margin: 0;
}

#homepage-intro>div:first-child h2.gradient-text.large {
    font-style: normal;
    font-weight: 1000;
    font-size: 4rem;
    letter-spacing: 0.02em;
    text-transform: capitalize;
}

#homepage-image-container img {
    margin: 125px 0;
    width: 100%;
}

#easy-integration {
    margin-top: -220px;
}

#easy-integration #text-container {
    text-align: center;
}

.heading {
    text-transform: capitalize;
}

#easy-integration #text-container h3,
.heading {
    text-align: center;
    font-size: 3rem;
    margin: 0;
    font-weight: 700;
    letter-spacing: 0.02em;
}

#cards-wrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    margin: 75px 0 60px;
}

.homepage-card {
    margin: 20px 0;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.homepage-card>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10rem;
    border: 1px solid #8726b75a;
    border-radius: 15px;
    box-sizing: border-box;
    background: var(--white-shade-bg);
}

#our-partners {
    margin: 20px 0;
}

#benefits {
    margin: 70px 0;
}

#long-cards-wrapper {
    display: flex;
    gap: 20px;
    flex-flow: row wrap;
    justify-content: space-evenly;
    margin: 70px 0;
}

.long-card {
    overflow: hidden;
    width: 15rem;
    border: 1px solid;
    border-radius: 10px;
    display: grid;
    grid-auto-rows: 1fr 1fr;
}

.long-card .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
}

.long-card .text-wrapper {
    padding: 2px 8px;
    background: #3C41E6;
    border-radius: 1px;
}

.long-card .text-wrapper h2 {
    font-size: 16px;
}

.long-card .text-wrapper p {
    margin-top: 20px;
    font-size: 12px;
}

#partner-images-wrapper {
    display: flex;
    flex-flow: row wrap;
    gap: 70px;
    justify-content: center;
    margin: 50px 4rem;
}

#reviews-wrapper {
    overflow: hidden;
    cursor: grab;
}

.review-card-wrapper {
    margin: 0 30px;
    cursor: grab;
}

.review-card {
    background: #FFFF;
    padding: 35px;
    color: #000000;
    /* width: 50vw; */
}

.review-head {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
}

.review-head div:first-child {
    background: radial-gradient(77.25% 77.25% at 24.17% 22.75%, rgba(246, 246, 246, 0.75) 0%, rgba(185, 185, 185, 0.75) 100%);
    padding: 8px;
    width: fit-content;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.review-head div:last-child span {
    font-size: 24px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    margin-bottom: 6px;
}

.review-head div:last-child * {
    display: block;
}

.review-text-wrapper {
    margin: 30px 0;
}

.review-text-wrapper q {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
}

.review-text-wrapper p {
    font-size: 10px;
    font-weight: 400;
    line-height: 17px;
    text-transform: uppercase;
}

#about-us {
    color: #FFFF;
}

#about-us>div {
    margin: 60px 0;
}

#about-top {
    margin: 60px 0;
}

#about-top * {
    width: 70vw;
    text-align: center;
    margin: auto;
}

#about-top p {
    margin-top: 40px;
}

#brise-corousal .img-wrapper>div {
    border: 1px solid #FFFF;
    border-radius: 10px;
    padding: 30px;
    background: var(--white-shade-bg);
}

.img-wrapper img {
    border-radius: 10px;
    width: 100%;
    opacity: .5;
    transition: all .8 ease;
}

div .slick-current .img-wrapper img {
    opacity: 1;
}

div#brise-corousal button.slick-arrow {
    display: none !important;
}

#brise-corousal div.img-wrapper {
    scale: .75;
    opacity: .5;
    transition: all .5s ease;
}

#brise-corousal .slick-center div.img-wrapper {
    opacity: 1;
    scale: 1
}

#about-us #companies {
    background: var(--white-shade-bg);
    display: flex;
    gap: 20px;
    justify-content: space-evenly;
    padding: 50px 0;
    margin-bottom: 0;
}

#about-us #companies img {
    width: 15vw;
}

div#about-section {
    margin: 0;
    color: #000000;
    background: #D9D9D9;
    padding: 20px;
}

#about-section h2 {
    color: #171959;
    margin: 35px 0;
    font-size: 57px;
    font-weight: 700;
    line-height: 89px;
    letter-spacing: 0.02em;
}

#about-section p {
    text-align: center;
}

#about-section #images-wrapper {
    margin: 120px 0 40px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 20px;
}

#about-section #images-wrapper img {
    width: 25%;
    min-width: 280px;
    box-shadow: 0px 34.7482px 44.16px rgba(1, 1, 1, 0.07), 0px 16.56px 11.04px rgba(1, 1, 1, 0.06), 0px 5.52px 5.52px rgba(1, 1, 1, 0.05), 0px 5.52px 4.416px rgba(1, 1, 1, 0.04), 0px 2.208px 2.76px rgba(1, 1, 1, 0.03), 0px 0.992806px 1.104px rgba(1, 1, 1, 0.02);
    border-radius: 10px;
}

#about-section #images-wrapper img:nth-child(2) {
    translate: 0 -15%;
}

div#our-mission {
    margin-top: 0;
    background: var(--white-shade-bg);
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
    padding: 60px;
    text-align: center;
}

#partner-reviews {
    margin: 60px 0;
}

.partner-review-card {
    border: 1px solid #FFFF;
    display: flex;
    gap: 15px;
    background: var(--white-shade-bg);
    padding: 40px;
    border-radius: 20px;
    margin: 10px;
}

.partner-review-card img {
    width: 200px;
    border-radius: 10px;
    border: 6px solid #FFFF;
}

.partner-review-card p {
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
}

.partner-review-card .image-wrapper {
    translate: -100px 0;
}

.partner-review-card .profile span {
    margin: 20px 0 0;
    font-weight: 600;
    line-height: 24px;
    display: block;
}

.partner-review-card .profile small {
    font-size: 14px;
    line-height: 21px;
}

.partner-review-card .review-text q {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;
}

.partner-review-card .review-text p {
    margin-top: 40px;
    font-size: 14px;
    line-height: 29px;
}

div#questions-wrapper {
    margin-bottom: 0;
    border: 1px solid #ffffff32;
    border-width: 1px 0;
    background: var(--white-shade-bg);
    padding: 40px 60px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

#questions {
    width: 100%;
    margin: 40px 0;
}

#questions>div {
    display: flex;
    justify-content: space-between;
    padding: 25px 0;
    border-bottom: 1px solid #ffffff6d;
    font-size: 26px;
    font-weight: 500;
}

#questions button {
    background: none;
    border: none;
    outline: none;
    color: #FFFF;
    font-size: 28px;
    line-height: 28px;
}

#questions-wrapper>button {
    padding: 15px 30px !important;
}

#registration-form {
    display: flex;
    min-height: 100vh;
}

#registration-form #left {
    color: #FFFF;
    padding: 6vmax 0;
}

#registration-form #left #headings-container>span {
    padding-left: 4vmax;
    display: block;
}

#registration-form #left #headings-container>span:first-child {
    font-size: 96px;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: 0.02em;
}

#registration-form #left #headings-container>span:nth-child(2) {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.02em;
}

#org-logo-wrapper {
    display: flex;
    /* flex-flow: column nowrap; */
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 20px;
    margin: 60px auto;
}

#org-logo-wrapper>div {
    border-radius: 100%;
    /* background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%); */
    /* border: 16px solid #EFF3F9; */
    width: fit-content;
    height: fit-content;
    display: flex;
    aspect-ratio: 1;
}

#org-logo-wrapper>div img {
    margin: auto;
}

#org-logo-wrapper img {
    width: 96px;
}

#org-logo-wrapper span {
    font-size: 55px;
    font-weight: 600;
    line-height: 30px;
}

#registration-form #bg-img img {
    width: 40vw;
}

#merchant-form-wrapper {
    background: #FFFFFF;
    width: 100%;
    color: #000000;
    padding: 50px 0;
}

#merchant-form-wrapper #logo-title-wrapper {
    justify-content: center;
}

#merchant-form-wrapper #logo-title-wrapper h2 {
    justify-content: center;
    font-size: 48px;
    margin: 0;
}

#merchant-form-wrapper #logo-title-wrapper img {
    width: 64px;
}

#merchant-form-wrapper>#headings {
    margin: 30px 0;
}

#merchant-form-wrapper>#headings>h2,
#merchant-form-wrapper>#headings>span {
    margin: 0;
    text-align: center;
    display: block;
    letter-spacing: 0em;
}

#merchant-form-wrapper>#headings>h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
}

#merchant-form-wrapper>#headings>span {
    font-weight: 600;
    line-height: 24px;
}

#merchant-from {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 20px;
}

#merchant-from>div {
    width: 70%;
}

#merchant-from .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

#merchant-from .input-wrapper input {
    box-sizing: border-box;
    background: #EFF3F9;
    opacity: 0.5;
    padding: 16px 20px;
    border: 1px solid #171959;
    box-shadow: 0px 34.7482px 44.16px rgba(1, 1, 1, 0.07), 0px 16.56px 11.04px rgba(1, 1, 1, 0.06), 0px 5.52px 5.52px rgba(1, 1, 1, 0.05), 0px 5.52px 4.416px rgba(1, 1, 1, 0.04), 0px 2.208px 2.76px rgba(1, 1, 1, 0.03), 0px 0.992806px 1.104px rgba(1, 1, 1, 0.02);
    border-radius: 10px;
    outline: none;
    font-size: 20px;
}

#merchant-from .input-wrapper input:focus {
    opacity: 1;
}

#merchant-from .input-wrapper label {
    font-weight: 400;
    line-height: 24px;
}

.flex-input-containers {
    display: flex;
    justify-content: space-between;
}

.flex-input-containers>* {
    width: 48%;
}

#merchant-from button {
    margin-top: 30px;
    border-radius: 10px;
    width: 50%;
    font-size: 24px;
    font-weight: 700;
}

#pricing {
    display: flex;
    color: #FFFF;
    margin-bottom: 60px;
}

#pricing #left {
    padding: 30px 20px 30px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#pricing #left h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.02em;
    margin: 0;
}

#pricing #left p {
    font-size: 14px;
    line-height: 34px;
}

#pricing #details {
    background: var(--white-shade-bg);
    border-radius: 10px 0 0 10px;
    padding: 50px;
    border: 1px solid #ffffff3f;
}

#pricing #details>div {
    border: 1px solid #ffffff3f;
    background: var(--white-shade-bg);
    border-radius: 10px;
}

#pricing #top-content>div:first-child {
    display: flex;
    text-align: center;
    padding: 30px 10px;
    gap: 60px;
}

#pricing #top-content>div:first-child span {
    font-size: 44px;
    font-weight: 700;
    line-height: 90px;
    letter-spacing: 0.02em;
}

#pricing #top-content>div:first-child p {
    margin: 0;
}

#pricing #top-content>div:last-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    /* background: var(--white-shade-bg);
    border-top: 1px solid #ffffff3f; */
    padding: 30px;
}

#pricing #top-content>div:last-child span {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.02em;
}

#pricing #top-content>div:last-child button {
    min-width: 25%;
}

#pricing #bottom-content {
    padding: 10px 20px 0 50px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#pricing #bottom-content img {
    height: 200px;
}

#pricing #bottom-content span {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.02em;
}

#pricing #bottom-content p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

#pricing #layout-image-wrapper {
    position: relative;
}

#layout-image-wrapper img {
    position: absolute;
    left: 0;
    width: 40vw;
    max-width: 500px;
    translate: -50px -60%;
}

#contact-us {
    color: #FFFFFF;
}

#contact-us #head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    background: var(--white-shade-bg);
    border: 1px solid #ffffff32;
    padding: 60px;
}

#contact-us #head h2 {
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
}

#contact-us #head p {
    width: 70%;
    font-size: 14px;
    letter-spacing: 0em;
    margin: 0;
}

#contact-us #head img {
    width: 40%;
}

#contact-details {
    background: #FFFF;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #000000;
    border-radius: 20px;
    margin: auto 10%;
    translate: 0 -50%;
}

#contact-details span {
    display: block;
    text-align: center;
    font-size: 14px;
    line-height: 34px;
}

#contact-details span:first-child {
    font-size: 28px;
    font-weight: 1000;
    line-height: 45px;
    letter-spacing: 0em;
}

#phone-icon-wrapper {
    border: 1px solid #6c15a27e;
    translate: 0 -25%;
    background: #050630f9;
    padding: 10px;
    border-radius: 100%;
    overflow: hidden;
    scale: 1.25;
}

#phone-icon-wrapper>div {
    border-radius: 100%;
    background: linear-gradient(110.63deg, #193670 10.43%, #171959 65.49%);
    padding: 35px;
    display: flex;
}

#phone-icon-wrapper img {
    width: 56px;
}

#need-more-help {
    background: var(--white-shade-bg);
    padding: 30px;
    border-radius: 16px;
    width: 70vw;
    margin: 7.5vmin auto;
    text-align: center;
}

#need-more-help::before {
    padding: 1px;
    border-radius: 10px;
}

#need-more-help #inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 45px 0;
}

#need-more-help #inputs>div {
    width: 100%;
    display: flex;
}

#need-more-help #inputs>div::before {
    padding: 1px;
    border-radius: 6px;
}

#need-more-help #inputs>div .user-input-elem {
    background: var(--white-shade-bg);
    padding: 16px;
    color: #FFFFFF;
    border: none;
    outline: none;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
}

#need-more-help #inputs>div .user-input-elem::placeholder {
    color: #FFFF;
}

#need-more-help #inputs>div textarea {
    resize: vertical;
    color: #FFFF;
}

div#help-buttons>* {
    width: 40%;
    max-width: 200px;
}

#need-more-help #help-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

#need-more-help #help-buttons a button {
    width: 100%;
}